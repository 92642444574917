'use strict'

angular.module('app').
factory('ReviewService', ['$rootScope', '$http','$log',
        function ($rootScope, $http,$log) {
            return {
                saveReview: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/review/save',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                saveReviewCustom: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/review_custom/save',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getReviewAssignmentSubmissions: function (conference_id, submission_type_id){
                    var promise = $http.get($rootScope.global_app.uri + '/review/assignments/submissions/' + conference_id + '/' + submission_type_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getReviewAssignments: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/review/assignments').then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getReview: function (conference_id, submission_type_id, user_submission_id){
//
//                    alert(conference_id + '|' + submission_type_id + '|' + user_submission_id);
//
                    var promise = $http.get($rootScope.global_app.uri + '/review/submission/' + conference_id + '/' + submission_type_id + '/' + user_submission_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                }
            }
        }
    ]
);