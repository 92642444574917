'use strict';

angular.module('app')
    .controller('AuthorsController', [
            '$http', '$state', '$rootScope', '$scope', 'toaster',
            'AuthorsService',
            'DisclosuresService',
            'UserService',
            'usSpinnerService',
            '$uibModal',
            '$stateParams', '$filter', '$log', '$window',
            function ($http, $state, $rootScope, $scope, toaster,
                      Authors,
                      Disclosures,
                      User,
                      usSpinnerService,
                      $uibModal,
                      $stateParams, $filter, $log, $window) {

                $rootScope.show_back = true;

                // Spinner
                function startSpin(key) {
                    usSpinnerService.spin(key);
                };

                function stopSpin(key) {
                    usSpinnerService.stop(key);
                };

                $scope.user_submission_id = $rootScope.last_submission_id;

                // Authors vars
                var all_authors = [];
                $scope.prior_co_authors = [];
                $scope.authors_existing_found = null;
                $scope.author_added = false; // author has been added from search
                $scope.prior_author_added = false;
                $scope.author_exists = false; // authors found by search

                $scope.prior_co_author_exists = false; // authors found by search

                $scope.author_search_by_email = false; // ?
                $scope.new_author = [];
                $scope.user_submission_authors = [];
                $scope.create_author_form = false;
                $scope.countries = [];
                $scope.states = [];
                $scope.new_author_profile = [];

                $scope.author_field_rules = [
                    'author-first',
                    'author-point-of-contact',
                    'author-presenting',
                    'author-presenting-disclosure',
                    'author-presenting-profile'
                ]; //TODO add to db

                $scope.clearAuthorSearch = function () {
                    $scope.authors_existing_found = null;
                    $scope.author_exists = false;
                    $scope.author_notfound_add = false;
                    $scope.create_author_form = false;

                    $scope.new_author = {};
                }

                Authors.getPriorCoAuthors($scope.user_submission_id).then(function (data) {

 //                   stopSpin('author_search')
                     if (data.status == 'success') {
                         $scope.prior_co_author_exists = true;
                         $scope.prior_co_authors = data.prior_co_authors;
                    } else if (data.status == 'none') {
                         $scope.prior_co_author_exists = false;
                    } else if (data.status == 'error') {
                        toaster.pop('error', 'Author - prior Co-author search', data.error);
                    }
                });


                $scope.includeAllPriorCoAuthors = function(){
                    angular.forEach( $scope.prior_co_authors, function (author, key) {
                            if (author.include !== 'Y') {
                                author.include = 'Y';
                            } else {
                                author.include = ''
                            }
                    });
                }



                $scope.addAuthors = function () {
                    angular.forEach( $scope.prior_co_authors, function (author, key) {
    //                    alert('pre Author:' + JSON.stringify(author));
                        if (author.include == 'Y' || author.include == true) {


                            $scope.addAuthor(author,'prior_co_author');
                            author.include = false;
                            $scope.prior_author_added = true;
                        }
                    });

                }


                $scope.removeAuthor = function (item) {

                    if ( ($scope.user_view) && ($scope.user_view == 'my_submissions') ) {

                        // if attempting to delete an author from a submitted submission make sure it will not break rules

                        var author_counts = getAuthor_counts();

                        var ok_to_remove = true;
                        var remove_ng_msg = new Array();

                        if ((item.role1 == 'First Author') && (author_counts.first_author <= 1)){
                            remove_ng_msg.push('You may not remove the First Author');
                            ok_to_remove = false;
                        }

                        if ((item.role3 == 'Point of Contact') && (author_counts.point_of_contact <= 1)){
                            remove_ng_msg.push('You may not remove the last Point of Contact');
                            ok_to_remove = false;
                        }

                        if ((item.role4 == 'Presenting Author') && (author_counts.presenting_author <= 1)){
                            remove_ng_msg.push('You may not remove the Presenting Author');
                            ok_to_remove = false;
                        }

                        if ( !ok_to_remove) {
                            toaster.pop('error', 'Author', remove_ng_msg.join(','));
                            return false;
                        }

                    }

                    var remove_data = {
                        'user_submission_id': $scope.user_submission_id,
                        'id': item.id,
                        admin_user: 'submitter'
                    }

                    Authors.removeAuthor(remove_data).then(function (data) {
                        if (data.status == 'success') {
                            $rootScope.$broadcast('updateAuthorsListFromAuthors');
                            getAuthors();

                        } else if (data.status == 'error') {
                            toaster.pop('error', 'Author', data.error);
                        } else {
                            toaster.pop('error', 'Author', 'Error removing author');
                        }
                    });
                }


                $scope.setAuthor = function (item, new_role, model, $event) {
                    // this used to be main edit function
                    // now splitting edits functions between draft mode submissions and submitted submissions
                    // draft mode submissions simply error message on screen and prevent proceeding
                    // submitted mode submissions prevent

                     if ( ($scope.user_view) && ($scope.user_view == 'my_submissions') ) {
                        $scope.setAuthor_my_submissions(item, new_role, model, $event);
                    } else {
                        $scope.setAuthor_submission_details(item, new_role, model, $event);
                    }

                }

                function getAuthor_counts(){

                    var author_counts = { 'presenting_author' : 0,
                        'first_author' : 0,
                        'co_author' : 0,
                        'point_of_contact' : 0
                    };

                    angular.forEach($scope.user_submission_authors, function (author, key) {
                        if (author.role1 == 'First Author') {
                            author_counts.first_author += 1;
                        }
                        if (author.role2 == 'Co-Author') {
                            author_counts.co_author += 1;
                        }
                        if (author.role3 == 'Point of Contact') {
                            author_counts.point_of_contact += 1;
                        }
                        if (author.role4 == 'Presenting Author') {
                            author_counts.presenting_author += 1;
                        }
                    });

                    return author_counts;

                }

                $scope.setAuthor_my_submissions = function (item, new_role, model, $event) {

                    // this function used when adding and editing submission details while still in draft status

                    var author_counts = getAuthor_counts();

                    // check if deselecting as co-author and NOT a point of contact
                    if ( (new_role == 'co') && ( item.role3 != 'Point of Contact')  ) {
                        // only allow 1 first author
                        alert('You may not de-select as Co-Author unless this is a Point of Contact.');
                        $event.preventDefault();
                        return false;
                    }

                    var ok_to_set = true;
                    var ok_to_swap = false;

                    angular.forEach($scope.author_field_rules, function (rule) {

                        switch (rule) {
                            case 'author-first' :
/*
                                if ( (new_role == 'first') && ( item.role1 != 'First Author')  ) {
                                    // only allow 1 first author
                                  if (author_counts.first_author > 0) {
                                      ok_to_set = false;
                                      ok_to_swap = true;
                                      // $event.preventDefault();
                                  }
                                }
*/

                                // only allow 1 presenting author
                                if (new_role == 'first' ) {

                                    if ( (item.role1 == 'First Author') && (author_counts.first_author == 1)) {
                                        toaster.pop('error', 'Author', 'You may not de-select the First Author. Select another author to swap as the First Author.');
                                        ok_to_set = false;
                                        $event.preventDefault();

                                    }

                                    if ( (item.role1 != 'First Author') && (author_counts.first_author == 1)) {

     //   commented code only needed if disclosure and profile needed for First Author
     //                                   if ((!item.disclosure) || (!item.disclosure)) {
     //                                       toaster.pop('error', 'Author', 'Profile and Disclosure must be completed before you can set this Author as Presenting Author.');
     //                                       ok_to_set = false;
     //                                       $event.preventDefault();
     //                                   } else {
                                        ok_to_set = false;
                                        ok_to_swap = true;

     //                                   }

                                    }

                                }

                                break;

                            case 'author-presenting' :

                                // only allow 1 presenting author
                                if (new_role == 'presenting' ) {

                                    if ( (item.role4 == 'Presenting Author') && (author_counts.presenting_author == 1)) {
                                        toaster.pop('error', 'Author', 'You may not de-select the Presenting Author. Select another author to swap as the Presenting Author.');
                                        ok_to_set = false;
                                        $event.preventDefault();

                                    }

                                    if ( (item.role4 != 'Presenting Author') && (author_counts.presenting_author == 1)) {

                                        if ((!item.disclosure) || (!item.disclosure)) {
                                            toaster.pop('error', 'Author', 'Profile and Disclosure must be completed before you can set this Author as Presenting Author.');
                                            ok_to_set = false;
                                            $event.preventDefault();
                                        } else {
                                            ok_to_set = false;
                                            ok_to_swap = true;

                                        }

                                    }

                                }

                                break;

                            case 'author-point-of-contact' :

                                if (new_role == 'contact' ) {
                                    if ( (item.role3 == 'Point of Contact') && (author_counts.point_of_contact <= 1)) {
           //                             alert('You may not de-select the last Point of Contact. Submissions must have at least one Point of Contact');
                                        ok_to_set = false;
                                        toaster.pop('error', 'Author', 'You may not de-select the last Point of Contact. Submissions must have at least one Point of Contact.');
                                        $event.preventDefault();
                                    }

                                }

                                break;
                        }

                    });

                    // update author role
                    if (ok_to_set) {
                        var set_data = {
                            user_submission_id: $scope.user_submission_id,
                            id: item.id,
                            new_role: new_role,
                            action: angular.isDefined(model) ? 'add' : 'remove',
                            admin_user: 'submitter'
                        }

                        Authors.setAuthor(set_data).then(function (data) {
                            if (data.status == 'success') {

                                getAuthors();
                                $rootScope.$broadcast('updateAuthorsListFromAuthors');

                            } else if (data.status == 'error') {
                                toaster.pop('error', 'Author', data.error);
                            } else {
                                toaster.pop('error', 'Author', 'Error updating Author.');
                            }
                        });
                    } else if (ok_to_swap) {
                        var set_data = {
                            user_submission_id: $scope.user_submission_id,
                            id: item.id,
                            new_role: new_role,
                            action: 'swap',
                            admin_user: 'submitter'
                        }

                        Authors.setAuthorRoleExclusive(set_data).then(function (data) {
                            if (data.status == 'success') {

                                getAuthors();
                                $rootScope.$broadcast('updateAuthorsListFromAuthors');

                            } else if (data.status == 'error') {
                                toaster.pop('error', 'Author', data.error);
                            } else {
                                toaster.pop('error', 'Author', 'Error updating Author.');
                            }
                        });
                    }




                }

                $scope.setAuthor_submission_details = function (item, new_role, model, $event) {

                 // this function used when adding and editing submission details while still in draft status

                    var ok_to_set = true;

                    angular.forEach($scope.author_field_rules, function (rule) {

                        switch (rule) {
                            case 'author-first' :

                                // only allow 1 first author
                                if (new_role == 'first' && item.role1 != 'First Author') {
                                    angular.forEach($scope.user_submission_authors, function (author, key) {
                                        if (author.role1 == 'First Author') {
                                            ok_to_set = false;
                                        }
                                    });
                                    if (!ok_to_set) {
                                        toaster.pop('error', 'First Author', 'Only one First Author is permitted. Please deselect the current First Author to make a change.');
                                        $event.preventDefault();
                                    }
                                }

                                break;

                            case 'author-presenting' :

                                // only allow 1 presenting author
                                if (new_role == 'presenting' && item.role4 != 'Presenting Author') {
                                    angular.forEach($scope.user_submission_authors, function (author, key) {
                                        if (author.role4 == 'Presenting Author') {
                                            ok_to_set = false;
                                        }
                                    });
                                    if (!ok_to_set) {
                                        toaster.pop('error', 'Presenting Author', 'Only one Presenting Author is permitted. Please deselect the current Presenting Author to make a change.');
                                        $event.preventDefault();
                                    } else {
                                        if ( !item.profession  || item.profession.length < 5 || item.profession == 'Other') {
                                            ok_to_set = false;
                                        }
                                        if (!ok_to_set) {
                                            toaster.pop('error', 'Presenting Author', 'Selected Author profile is incomplete. Presenting Author Profession must not be blank or "Other" Please edit Author profile "profession" .');
                                            $event.preventDefault();
                                        }
                                    }

                                    /**
                                    // check if disclosure exists / presenting author requires a disclosure
                                    if (ok_to_set && !item.disclosure) {
                                        ok_to_set = false
                                        toaster.pop('error', 'Presenting Author', 'Please complete the authors disclosure first.');
                                        $event.preventDefault();
                                    }
                                     **/
                                }

                                break;

                            case 'author-presenting-disclosure' :

                                break;

                            case 'author-presenting-profile' :

                                break;

                            case 'author-point-of-contact' :

                                break;
                        }

                    });


                    // update author role
                    if (ok_to_set) {
                        var set_data = {
                            user_submission_id: $scope.user_submission_id,
                            id: item.id,
                            new_role: new_role,
                            action: angular.isDefined(model) ? 'add' : 'remove',
                            admin_user: 'submitter'
                        }

                        Authors.setAuthor(set_data).then(function (data) {
                            if (data.status == 'success') {

                                getAuthors();
                                $rootScope.$broadcast('updateAuthorsListFromAuthors');

                            } else if (data.status == 'error') {
                                toaster.pop('error', 'Author', data.error);
                            } else {
                                toaster.pop('error', 'Author', 'Error updating Author.');
                            }
                        });
                    }
                }

                $scope.addAuthor = function (row, add_type) {
//                    alert('addAuthor here 3');
//alert(JSON.stringify(row));

                    $scope.prior_author_added = false;

                    var add_data = {
                        user_submission_id: $scope.user_submission_id,
                        id: row.id,
                        admin_user: 'submitter'
                    }

                    startSpin('author_search')

                    Authors.addAuthor(add_data).then(function (data) {

                        if (data.status == 'success') {

                            stopSpin('author_search');
                            if ( add_type && add_type == 'prior_co_author') {
                                angular.forEach( $scope.prior_co_authors, function (author, key) {
                                    if (author.id == row.id) {
                                       $scope.prior_co_authors.splice(key, 1);
                                    }
                                });

                            } else {
                                var index = $scope.authors_existing_found.indexOf(row);
                                if (index !== -1) {
                                    $scope.authors_existing_found.splice(index, 1);
                                }

                            }
                            getAuthors();
                            $rootScope.$broadcast('updateAuthorsListFromAuthors');
                            resetAuthorField();
                           $scope.author_added = true;

                        } else if (data.status == 'error') {
                            toaster.pop('error', 'Add Author', data.error);
                        } else {
                            toaster.pop('error', 'Add Author', 'Error adding Author');
                        }
                    });
                }

                $scope.addAnother = function () {
                    $scope.author_added = false;
                    resetAuthorField();
                    resetAuthorForm();

                }

                $scope.searchAuthor = function (new_author) {
                    var search_author_data = {
                        first_name: new_author.first_name,
                        last_name: new_author.last_name,
                        email: new_author.email,
                        institution: new_author.institution,
                        user_submission_id: $scope.user_submission_id,
                    }

                    startSpin('author_search')

                    Authors.searchAuthor(search_author_data).then(function (data) {

                        stopSpin('author_search')

                        resetAuthorField();

                        if (data.status == 'success') {
                            $scope.author_exists = true;
                            $scope.author_search_by_email = data.search_by_email;
                            $scope.authors_existing_found = data.existing_results;
                        } else if (data.status == 'none') {
                            $scope.author_notfound_add = true;
                        } else if (data.status == 'error') {
                            toaster.pop('error', 'Author', data.error);
                        }
                    });
                }

                // set country
                function setCountrySelected(country) {
                    if (country) {
                        return $filter('filter')($scope.countries, {name: country})[0];
                    }
                }

                // set state
                function setStateSelected(state) {
                    if (state) {
                        return $filter('filter')($scope.states, {name: state})[0];
                    }
                }

                $scope.updateStates = function () {
                    User.getStates($scope.new_author_profile.country.code).then(function (data) {
                        if (data.status == 'success') {
                            $scope.states = data.states;
                        } else {
                            $scope.states = [];
                        }
                    });
                }

                $scope.setProfessions = function (is_clinician) {
                    User.getProfessions(is_clinician).then(function (data) {
                        if (data.status == 'success') {
                            $scope.professions = data.professions;
                            $scope.specialties = data.specialties;
                        }
                    });
                }
                $scope.genders = ["Male","Female"];
                $scope.createNewAuthor = function () {
  //                  alert('createNewAuthor 2');
                    var author_data = {
                        'first_name': $scope.new_author_profile.first_name,
                        'middle_name': $scope.new_author_profile.middle_name,
                        'last_name': $scope.new_author_profile.last_name,
                        'email': $scope.new_author_profile.email,
                        'phone': $scope.new_author_profile.phone,
                        'institution': $scope.new_author_profile.institution,
                        'degree': $scope.new_author_profile.degree,
                        'city': $scope.new_author_profile.city,
                        'stateprovince': $scope.new_author_profile.stateprovince,
                        'country': $scope.new_author_profile.country,
                        'user_submission_id': $scope.user_submission_id,
                        'is_clinician': $scope.new_author_profile.is_clinician,
                        'profession': $scope.new_author_profile.profession,
                        'specialty': $scope.new_author_profile.specialty,
                        'gender': $scope.new_author_profile.gender,
                        'academic_affiliation': $scope.new_author_profile.academic_affiliation,
                        'title': $scope.new_author_profile.title,
                        'crf_newsletter': $scope.new_author_profile.crf_newsletter,
                        'tct_newsletter': $scope.new_author_profile.tct_newsletter,
                        'tctmd_newsletter': $scope.new_author_profile.tctmd_newsletter
                    }

                    startSpin('author_search')

                    Authors.createAuthor(author_data).then(function (data) {

                        stopSpin('author_search')

                        if (data.status == 'success') {
                            resetAuthorField();
                            $scope.author_added = true;
                            $scope.new_author_profile = [];

                            $rootScope.$broadcast('updateAuthorsListFromAuthors');

                            // reload author list
                            getAuthors();

                        } else if (data.status == 'error') {
                            //resetAuthorField();
                            toaster.pop('error', 'Author', data.error);
                        } else {
                            toaster.pop('error', 'Author', data);
                        }
                    });

                }

                $scope.showCreateForm = function () {
                    $scope.create_author_form = true;
                    $scope.author_exists = false;
                    $scope.author_notfound_add = false;
                    $scope.create_author = {};

                    // get countries values for dropdown
                    User.getCountries().then(function (data) {
                        if (data.status == 'success') {
                            $scope.countries = data.countries;
                        }
                    });
                }

                $scope.field_author_options = {
                    handle: '> .sortable-dd3-handle',
                    stop: function (e, ui) {

                        var author_order = {
                            'sorted_list': $scope.user_submission_authors.map(function (i) {
                                return i.value;
                            }).join(', ')
                        };

                        Authors.sortAuthors(author_order).then(function (data) {

                            if (data.status == 'success') {

                                getAuthors();

                            } else if (data.status == 'error') {
                                toaster.pop('error', 'Author', data.error);
                            } else {
                                toaster.pop('error', 'Author', 'Error sorting');
                            }
                        });
                    }
                };

                // Disclosures
                $scope.loadDisclosures = function (author_id, section) {

                    $scope.modal_data = {
                        conference_id: 0,
                        author_id: author_id,
                        section: section
                    }

                    // Create modal controller
                    var disclosureModal = $uibModal.open({
                        templateUrl: '/tpl/app_submission_disclosures_modal.html',
                        controller: 'SubmissionDisclosuresModalController',
                        size: 'lg',
                        windowClass: 'disclosures-modal',
                        resolve: {
                            modal_data: function () {
                                return $scope.modal_data;
                            },
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/submission_disclosures_modal.min.js']);
                                }
                            ]
                        }
                    });

                    disclosureModal.result.then(function (selectedItem) {
                        // perform when modal is closed

                        $rootScope.$broadcast('updateAuthorsListFromAuthors');
                        getAuthors();

                    }, function () {
                        //$log.info('Modal dismissed at: ' + new Date());
                    });

                };

                // Disclosures
                $scope.loadAuthorEdit = function (author_id) {

                    $scope.modal_data = {
                        author_id: author_id,
                    }

                    // Create modal controller
                    var authorModal = $uibModal.open({
                        templateUrl: '/tpl/app_submission_author_modal.html',
                        controller: 'SubmissionAuthorModalController',
                        size: 'lg',
                        windowClass: 'author-modal',
                        resolve: {
                            modal_data: function () {
                                return $scope.modal_data;
                            },
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/submission_author_modal.min.js']).then(
                                        function () {
                                            return $ocLazyLoad.load([
                                                'toaster',
                                                'ui.select'
                                            ]);
                                        }
                                    );
                                }
                            ]
                        }
                    });

                    authorModal.result.then(function (selectedItem) {
                        // perform when modal is closed
                        getAuthors();

                    }, function () {
                        //$log.info('Modal dismissed at: ' + new Date());
                    });

                };

                function resetAuthorForm() {
                    $scope.new_author.email = null;
                    $scope.new_author.first_name = null;
                    $scope.new_author.last_name = null;
                    $scope.new_author.institution = null;
                }

                function resetAuthorField() {
                    $scope.author_added = false;
                    $scope.author_exists = false;
                    $scope.author_notfound_add = false;
                    $scope.author_search_by_email = false;
                    $scope.create_author_form = false;
                }

                function getAuthors() {
                    Authors.getAuthors($scope.user_submission_id).then(function (data) {

                        if (data.status == 'success') {
                            $scope.author_field = data.author_field
                            $scope.user_submission_authors = data.authors;

                            // set Point of Contact validation messages when editing authors before final submission
                            if (angular.isDefined($scope.submission_form)) {
                                checkValidAuthorsForm();
                            }

                            angular.forEach($scope.user_submission_authors, function (author, key) {
                                all_authors[author.id] = author;
                            });

                        } else if (data.status == 'error') {

                            toaster.pop('error', 'Author', data.error);
                        }

                    });
                }

                $scope.$on('updateAuthorsList', function(e) {
                    getAuthors();
                });

                $scope.$on('updateAuthorsListFromPocs', function(e) {
                    getAuthors();
                });

                getAuthors();

                /**$scope.showAuthorDelete = function (index, showPresenting, showContact) {
                    return (index > 0 && (!showPresenting && !showContact));
                }

                 $scope.showPresentingField = function (item) {
                    var presenting_count = 0;

                    angular.forEach($scope.user_submission_authors, function (author, key) {
                        if (author.role2 == 'Presenting Author') {
                            ++presenting_count;
                        }
                    });

                    if ((presenting_count == 1 && all_authors[item.id].role2 == 'Presenting Author')) {
                        return true;
                    } else {
                        return false;
                    }
                }**/

                /**
                 $scope.showContactField = function (item) {
                    var contact_count = 0;

                    angular.forEach($scope.user_submission_authors, function (author, key) {
                        if (author.role3 == 'Point of Contact') {
                            ++contact_count;
                        }
                    });

                    if (contact_count == 1 && all_authors[item.id].role3) {
                        return true;
                    } else {
                        return false;
                    }
                }**/

                /**
                 $scope.showFirstField = function (item) {
                    var first_count = false;

                    angular.forEach($scope.user_submission_authors, function (author, key) {
                        if (author.role1 == 'First Author') {
                            first_count = true;
                        }
                    });

                    return first_count;
                }
                 **/

                function checkValidAuthorsForm() {

                    if (!$scope.user_submission_authors.length){

                        // if no authors are found set error messages for default rules
                        var default_rules  = [
                            'author-first',
                            'author-point-of-contact',
                            'author-presenting',
                        ];

                        angular.forEach(default_rules, function (rule){
                            $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity(rule, false);
                        });

                    } else {
                        angular.forEach($scope.author_field_rules, function (rule) {

                            switch (rule) {
                                case 'author-first' :

                                    // First Author is required for all
                                    var first_author_exists = false;

                                    angular.forEach($scope.user_submission_authors, function (author, key) {
                                        if (author.role1 == 'First Author') {
                                            first_author_exists = true;
                                        }
                                    });

                                    if (!first_author_exists) {
                                        $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity("author-first", false);
                                    } else {
                                        $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity("author-first", true);
                                    }
                                    break;

                                case 'author-presenting' :

                                    // Presenting Author is required for all
                                    var presenting_author_exists = false;

                                    angular.forEach($scope.user_submission_authors, function (author, key) {
                                        if (author.role4 == 'Presenting Author') {
                                            presenting_author_exists = true;
                                        }
                                    });

                                    if (!presenting_author_exists) {
                                        $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity("author-presenting", false);
                                    } else {
                                        $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity("author-presenting", true);
                                    }

                                    break;

                                case 'author-presenting-disclosure' :

                                    // Presenting must fill out disclosure
                                    var presenting_author_disclosure = false;
                                    angular.forEach($scope.user_submission_authors, function (author, key) {
                                        if (author.role4 == 'Presenting Author') {
                                            if (!author.disclosure) {
                                                $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity("author-presenting-disclosure", false);
                                            } else {
                                                $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity("author-presenting-disclosure", true);
                                            }
                                        }
                                    });

                                    break;

                                case 'author-presenting-profile' :

                                    // Presenting must fill out profile
                                    var presenting_author_profile = false;
                                    angular.forEach($scope.user_submission_authors, function (author, key) {
                                        if (author.role4 == 'Presenting Author') {
                                            if (!author.profile_complete) {
                                                $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity("author-presenting-profile", false);
                                            } else {
                                                $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity("author-presenting-profile", true);
                                            }
                                        }
                                    });

                                    break;

                                case 'author-point-of-contact' :

                                    // Point of contact is required for all
                                    var point_of_contact = false;

                                    angular.forEach($scope.user_submission_authors, function (author, key) {
                                        if (author.role3 == 'Point of Contact') {
                                            point_of_contact = true;
                                        }
                                    });

                                    if (!point_of_contact) {
                                        $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity("author-point-of-contact", false);
                                    } else {
                                        $scope.submission_form.parts[$scope.author_field.part_id]['field_' + $scope.author_field.id].$setValidity("author-point-of-contact", true);
                                    }
                                    break;
                            }

                        });
                    }

                    $scope.checkAllPartsValid();
                }

            }
        ]
    );