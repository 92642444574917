'use strict';

/* Controllers */

angular.module('app')
    .controller('AppController', ['$rootScope','$scope', '$localStorage', '$window','AuthService','$location','$state',
        function ($rootScope, $scope, $localStorage, $window, Auth, $location, $state) {
            // add 'ie' classes to html
            var isIE = !!navigator.userAgent.match(/MSIE/i);
            isIE && angular.element($window.document.body).addClass('ie');
            isSmartDevice($window) && angular.element($window.document.body).addClass('smart');

            // config
            $scope.app = {
                name: 'CALL FOR SCIENCE',
                version: '1.0',
                // for chart colors
                color: {
                    primary: '#7266ba',
                    info: '#23b7e5',
                    success: '#27c24c',
                    warning: '#fad733',
                    danger: '#f05050',
                    light: '#e8eff0',
                    dark: '#3a3f51',
                    black: '#1c2b36'
                },
                settings: {
                    themeID: 1,
                    navbarHeaderColor: 'bg-darkblue-only',
                    navbarCollapseColor: 'bg-darkblue-only',
                    asideColor: 'bg-purple',
                    headerFixed: false,
                    asideFixed: false,
                    asideFolded: false,
                    asideDock: false,
                    container: false
                }
            }
            $scope.session_id = '';
            $rootScope.global_app.name = $scope.app.name;

            $scope.back_history = function() {
                window.history.back();
            };

            $rootScope.show_back = false;

            $rootScope.isMobile = function(){

                // get width of document window
                var w = angular.element($window);
                var width = w.width();

                //w.bind('resize', function () {
                //width = w.width();
                //});

                if (width < 640){
                    return true;
                } else {
                    return false;
                }
            }

            $scope.$watch('app.settings', function () {
                if ($scope.app.settings.asideDock && $scope.app.settings.asideFixed) {
                    // aside dock and fixed must set the header fixed.
                    $scope.app.settings.headerFixed = true;
                }
                // save to local storage
                $localStorage.settings = $scope.app.settings;
            }, true);

            function isSmartDevice($window) {
                // Adapted from http://www.detectmobilebrowsers.com
                var ua = $window['navigator']['userAgent'] || $window['navigator']['vendor'] || $window['opera'];
                // Checks for iOs, Android, Blackberry, Opera Mini, and Windows mobile devices
                return (/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/).test(ua);
            }

            $scope.gotoBackend = function(){

                if ($location.absUrl().indexOf('#') !== -1){
                    var idx = $location.absUrl().indexOf('#');
                    var newloc =  $location.absUrl().substr(0,idx) + 'backend';
                    window.location.href = newloc;
                }

            }

            $scope.logout = function(){
                Auth.check_okta().then(function (data) {
                    if ( data && data.id) {
                        $scope.session_id = data.id;
                        Auth.logout($scope.session_id).then(function (data) {
                            $state.go('access.signin');
                        });
                    } else {
                        Auth.logout('1').then(function (data) {
                            $state.go('access.signin');
                        });
                    }
                });

/*
                Auth.logout($scope.session_id).then(function (data) {
                    $state.go('access.signin');
                });
*/
            }

            // open image thumb lightbox view
            $scope.openLightboxModal = function (field_id, images) {

                var img_arr  = [];
                var img_index = 0;

                angular.forEach(images, function(value, key) {
                    this.push({
                        'url': '/image/show/' + value.id + '/?_ts=' + new Date().getTime(),
                        'thumbUrl': '/image/show/' + value.id + '/thumb?_ts=' + new Date().getTime()
                    });

                    if (value.id == field_id){
                        img_index = key;
                    }

                }, img_arr);

                Lightbox.openModal(img_arr, img_index);
            };


            $scope.getFieldVisibility = function(content_type, field_type, user_view, user_sub_view, is_grade){

                // check if view is 'grade', displays non-editable/printable submission content
                if (user_view == 'grade'){
                    // display field if external print and authors field
                    if (user_sub_view == 'export_print' && field_type == 'authors'){
                        return true;
                    }

                    // display all other fields set as is_grade
                    if (is_grade){
                        return true;
                    }

                } else {
                    if (content_type == 'field'){
                        return true;
                    }
                }
            }
        }
    ]);
