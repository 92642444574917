'use strict'

angular.module('app').
factory('DisclosuresService', ['$rootScope', '$http','$log',
        function ($rootScope, $http,$log) {
            return {
                getDisclosure: function (conference_id, author_id, section){
                    var promise = $http.get($rootScope.global_app.uri + '/disclosure/author/'+conference_id+'/'+author_id+'/'+section).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getCompanies: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/disclosure/companies').then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getUserDisclosure: function (conference_id){
                    var promise = $http.get($rootScope.global_app.uri + '/disclosure/userdisclosure/' + conference_id ).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getMyDisclosures: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/disclosure/mydisclosures').then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                saveDisclosure: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/disclosure/save',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                delConflict: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/disclosure/conflict/delete',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },

            }
        }
    ]
);