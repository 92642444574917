'use strict'

angular.module('app').
factory('AuthorSubmissionsService', ['$rootScope', '$http','$log',
        function ($rootScope, $http,$log) {
            return {
                getAuthorSubmissions: function (conference_id, author_id, section){
                    var promise = $http.get($rootScope.global_app.uri + '/author_submissions').then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getAuthorPresentations: function (conference_id, author_id, section){
                    var promise = $http.get($rootScope.global_app.uri + '/author_presentations').then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                 setAuthorPresentationConfirmed: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/author_presentation_confirm',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                }
            }
        }
    ]
);