'use strict'

angular.module('app').
factory('FileService', ['$rootScope', '$http','$log',
        function ($rootScope, $http, $log) {
            return {
                deleteFile: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/file/delete',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                }
            }
        }
    ]
);