'use strict';

angular.module('app')
    .run(
    ['$http', '$cookies', '$rootScope', '$state', '$stateParams', 'AuthService','$location', 'UserService',
        function ($http, $cookies, $rootScope, $state, $stateParams, Auth, $location, User) {
            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;
            $rootScope.log_user_activity = true;
            $rootScope.log_user_activity_all = false;
            $http.defaults.headers.post['X-CSRFToken'] = $cookies.csrftoken;
            $rootScope.logUserActivity = function(toState, toParams, fromState) {
                var formData = {
                    'fromState': fromState.name,
                    'toState': toState.name,
                    'toParams': ''
                };
                User.logUserActivity(formData).then(function (data) {
                    if (data && data.status == 'success') {
                        if (data.log_user_activity !== true) {
                            $rootScope.log_user_activity = false;
                        }
                        if (data.log_user_activity_all == true) {
                            $rootScope.log_user_activity_all = true;
                        } else {
                            $rootScope.log_user_activity_all = false;
                        }
                    } else {
                    }
                });
            }

            $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
                if ($rootScope.log_user_activity == true) {
                    if ((fromState.name == 'access.signin') && (toState.name == 'app.dashboard')) {
                        $rootScope.logUserActivity(toState, toParams, fromState);
                    } else if ($rootScope.log_user_activity_all == true){
                        $rootScope.logUserActivity(toState, toParams, fromState);
                    }
                }
                // Set last state
                if (toState.name != 'access.signin') {
                    $rootScope.returnToState = toState;
                    $rootScope.returnToStateParams = toParams;
                    // code below to force redirect of bad link sent
                }

                Auth.check().then(function (data) {
                    if (data && data.status == 'success') {
                        if ($rootScope.returnToState) {
                            $state.go($rootScope.returnToState, $rootScope.returnToStateParams);
                            $rootScope.returnToState = '';
                            $rootScope.returnToStateParams = '';
                        }
                    } else {
                        if (toState.authenticate) {
                            $state.go("access.signin");
                            event.preventDefault();
                        }
                    }
                });
            });
        }
    ]
)
    .config(
    ['$stateProvider', '$urlRouterProvider', 'JQ_CONFIG',
        function ($stateProvider, $urlRouterProvider, JQ_CONFIG) {

            $urlRouterProvider
                .otherwise('/app/dashboard');

            $stateProvider
                .state('conference', {
                    url: '/c/{keyword}',
                    controller: 'ConferenceController',
                    resolve: {
                        deps: ['uiLoad',
                            function (uiLoad) {
                                return uiLoad.load(['js/controllers/conference.min.js']);
                            }]
                    },
                    authenticate: false
                })
                .state('support', {
                    url: '/support/{event}',
                    controller: 'SupportController',
                    templateUrl: 'tpl/support.html',
                    params: { eventParam: '' },
                    resolve: {
                        deps: ['uiLoad',
                            function (uiLoad) {
                                return uiLoad.load(['js/controllers/support.min.js']);
                            }]
                    },
                    authenticate: false
                })
                .state('access', {
                    url: '/access',
                    template: '<div ui-view class="fade-in-right-big smooth"></div>'
                })
                .state('access.signin', {
                    url: '/signin/{token}',
                    templateUrl: 'tpl/access_signin.html',
                    resolve: {
                        deps: ['uiLoad',
                            function (uiLoad) {
                                return uiLoad.load(['js/controllers/signin.min.js']);
                            }]
                    },
                    authenticate: false
                })
                .state('app', {
                    abstract: true,
                    url: '/app',
                    templateUrl: 'tpl/app.html'
                })
                .state('app.dashboard', {
                    url: '/dashboard',
                    controller: 'DashboardController',
                    templateUrl: 'tpl/app_dashboard.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/dashboard.min.js']);
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.start', {
                    url: '/start/{keyword}/{bypass_update_user_conf_profile}',
                    controller: 'StartController',
                    templateUrl: 'tpl/app_start.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/start.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load([
                                            ''
//                                            'angularMoment'
                                        ]);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.eligibility', {
                    url: '/eligibilty/{conference_id}/{submission_type_id}',
                    controller: 'EligibilityController',
                    templateUrl: 'tpl/app_eligibility.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/eligibility.min.js']);
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.submission', {
                    url: '/submission/{conference_id}/{submission_type_id}/{user_submission_id}',
                    controller: 'SubmissionController',
                    templateUrl: 'tpl/app_submission.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/submission.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load([
                                            'ui.tinymce',
                                            'angularSpinner',
                                            'smart-table',
                                            'ngCkeditor',
                                            'ngFileUpload',
                                            'bootstrapLightbox',
                                            'ui.select',
                                            'toaster'
                                        ]);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.confirm', {
                    url: '/submission/confirm/{conference_id}/{submission_type_id}/{user_submission_id}',
                    controller: 'SubmissionConfirmController',
                    templateUrl: 'tpl/app_submission_confirm.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/submission_confirm.min.js']);
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.submissions', {
                    url: '/submissions',
                    controller: 'SubmissionsController',
                    templateUrl: 'tpl/app_submissions.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/submissions.min.js']);
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.submissions_details', {
                    url: '/submissions/details/{conference_id}/{submission_type_id}/{user_submission_id}',
                    controller: 'SubmissionsDetailsController',
                    templateUrl: 'tpl/app_submissions_details.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/submissions_details.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['bootstrapLightbox','ui.select','angularSpinner']);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.disclosures', {
                    url: '/disclosures',
                    controller: 'DisclosuresController',
                    templateUrl: 'tpl/app_disclosures.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/disclosures.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['bootstrapLightbox','ui.select','angularSpinner']);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.profile', {
                    url: '/profile/{user_profile_conf_keyword}',
                    controller: 'ProfileController',
                    templateUrl: 'tpl/app_profile.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/profile.min.js']);
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.profile_details', {
                    url: '/profile/details/{user_profile_conf_keyword}',
                    controller: 'ProfileDetailsController',
                    templateUrl: 'tpl/app_profile_details.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/profile_details.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['angularSpinner','toaster','ngImgCrop','ui.select', 'ngFileUpload',]);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.reviewer_conferences', {
                    url: '/reviewer/conferences',
                    controller: 'ReviewerConferencesController',
                    templateUrl: 'tpl/app_reviewer_conferences.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/reviewer_conferences.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['ui.select']);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.reviewer_preferences', {
                    //url: '/reviewer/preferences/{conference_id}/{submission_type_id}',
                    url: '/reviewer/preferences/{conference_id}/{reviewer_id}',
                    controller: 'ReviewerPreferencesController',
                    templateUrl: 'tpl/app_reviewer_preferences.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/reviewer_preferences.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['ui.select']);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.reviewer_preferences_confirm', {
                    url: '/reviewer/preferences/{conference_id}/confirm',
                    controller: 'ReviewerConfirmController',
                    templateUrl: 'tpl/app_reviewer_preferences_confirm.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/reviewer_confirm.min.js']);
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.review_assignments', {
                    url: '/review/assignments',
                    controller: 'ReviewAssignmentsController',
                    templateUrl: 'tpl/app_review_assignments.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/review_assignments.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['toaster'])
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.prescreen', {
                    url: '/prescreen',
                    controller: 'PreScreenController',
                    templateUrl: 'tpl/app_prescreen.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/prescreen.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['toaster'])
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.prescreen_submissions', {
                    url: '/prescreen/submissions/{conference_id}/{submission_type_id}',
                    controller: 'PreScreenSubmissionsController',
                    templateUrl: 'tpl/app_prescreen_submissions.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/prescreen_submissions.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['toaster','smart-table'])
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.prescreen_details', {
                    url: '/prescreen/details/{conference_id}/{submission_type_id}/{user_submission_id}',
                    controller: 'PreScreenDetailsController',
                    templateUrl: 'tpl/app_prescreen_details.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/prescreen_details.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['bootstrapLightbox','toaster','angular-flot']);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.review', {
                    url: '/review/{conference_id}/{submission_type_id}',
                    controller: 'ReviewController',
                    templateUrl: 'tpl/app_review.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/review.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['angular-flot','toaster'])
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.review_details', {
                    url: '/review/{conference_id}/{submission_type_id}/details/{user_submission_id}',
                    controller: 'ReviewDetailsController',
                    templateUrl: 'tpl/app_review_details.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/review_details.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['bootstrapLightbox','toaster','angular-flot']);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.author_presentations', {
                    url: '/author_presentations',
                    controller: 'AuthorPresentationsController',
                    templateUrl: 'tpl/app_author_presentations.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/author_presentations.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['toaster']);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.author_submissions', {
                    url: '/author_submissions',
                    controller: 'AuthorSubmissionsController',
                    templateUrl: 'tpl/app_author_submissions.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/author_submissions.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['toaster']);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('app.author_submissions_details', {
                    url: '/author_submissions/details/{conference_id}/{submission_type_id}/{user_submission_id}',
                    controller: 'AuthorSubmissionsDetailsController',
                    templateUrl: 'tpl/app_author_submissions_details.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/author_submissions_details.min.js']).then(
                                    function () {
                                        return $ocLazyLoad.load(['bootstrapLightbox','angularSpinner','toaster']);
                                    }
                                );
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('print', {
                    abstract: true,
                    url: '/print',
                    templateUrl: 'tpl/print.html'
                })
                .state('print.submissions', {
                    url: '/submissions/{conference_id}/{submission_type_id}',
                    controller: 'PrintSubmissionsController',
                    templateUrl: 'tpl/print_submissions.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/print_submissions.min.js']);
                            }
                        ]
                    },
                    authenticate: true
                })
                .state('print.submission', {
                    url: '/submission/{conference_id}/{submission_type_id}/{user_submission_id}',
                    controller: 'PrintSubmissionController',
                    templateUrl: 'tpl/print_submission.html',
                    resolve: {
                        deps: ['uiLoad', '$ocLazyLoad',
                            function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['js/controllers/print_submission.min.js']);
                            }
                        ]
                    },
                    authenticate: false
                })

        }
    ]
);
