'use strict'

angular.module('app').
    factory('SubmissionService', ['$rootScope', '$http','$log',
        function ($rootScope, $http,$log) {
            return {
                getSubmissionTypes: function (conference_id) {
                    var promise = $http.get($rootScope.global_app.uri + '/submission/types/' + conference_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getSubmissionType: function (conference_id,submission_type_id,user_submission_id) {
                    var promise = $http.get($rootScope.global_app.uri + '/submission/type/' + conference_id + '/' + submission_type_id + '/' + user_submission_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getSubmission: function (conference_id,submission_type_id,user_submission_id) {
                    var promise = $http.get($rootScope.global_app.uri + '/submission/' + conference_id + '/' + submission_type_id + '/' + user_submission_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getSubmissionPublic: function (conference_id,submission_type_id,user_submission_id) {
                    var promise = $http.get($rootScope.global_app.uri + '/submission_public/' + conference_id + '/' + submission_type_id + '/' + user_submission_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getReviewSubmissions: function (conference_id,submission_type_id) {
                    var promise = $http.get($rootScope.global_app.uri + '/submissions_review/' + conference_id + '/' + submission_type_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getSubmissions: function () {
                    var promise = $http.get($rootScope.global_app.uri + '/submission/submissions').then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                submitSubmissionPart: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/submission/save',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                submitFinal: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/submission/save_final',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                deleteSubmission: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/submission/delete',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                deleteOptionalField: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/submission/delete_optional_field',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getPartsStatus: function (conference_id,submission_type_id,user_submission_id) {
                    var promise = $http.get($rootScope.global_app.uri + '/submission/parts_status/' + conference_id + '/' + submission_type_id + '/' + user_submission_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
            }
        }
    ]
);