'use strict'

angular.module('app').filter('unslugify', function() {
        return function(input) {
            if (!input)
                return;

            // make lower case and trim
            var str = input.trim();

            var frags = str.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        };
    }
);