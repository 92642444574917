'use strict'

angular.module('app')
    .directive('autoSaveForm', ['$timeout',
            function ($timeout) {
                return {
                    require: ['^form'],
                    link: function ($scope, $element, $attrs, $ctrls) {

                        var $formCtrl = $ctrls[0];
                        var savePromise = null;
                        var expression = $attrs.autoSaveForm || 'true';

                        $scope.$watch(function () {

                            if ($formCtrl.$dirty) {

                                if (savePromise) {
                                    $timeout.cancel(savePromise);
                                }

                                savePromise = $timeout(function () {

                                    savePromise = null;
                                    if ($scope.$eval(expression) !== false) {
                                        $formCtrl.$setPristine();
                                    }

                                }, 5000);
                            }

                        });
                    }
                }
            }
        ]
    );

