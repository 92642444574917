'use strict'

angular.module('app').
factory('PreScreenService', ['$rootScope', '$http','$log',
        function ($rootScope, $http,$log) {
            return {
                savePreScreen: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/prescreen/save',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getPreScreenConferences: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/prescreen/conferences').then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getScreenSubmissions: function (conference_id,submission_type_id){
                    var promise = $http.get($rootScope.global_app.uri + '/prescreen/submissions/' + conference_id + "/" + submission_type_id ).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getScreenSubmission: function (conference_id,submission_type_id, submission_id){
                    var promise = $http.get($rootScope.global_app.uri + '/prescreen/submission/' + conference_id + "/" + submission_type_id + "/" + submission_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                }
            }
        }
    ]
);