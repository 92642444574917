'use strict'

angular.module('app').
    factory('CategoriesService', ['$rootScope', '$http','$log',
        function ($rootScope, $http,$log) {
            return {
                getCategories: function (conference_id, submission_type_id) {

                    var promise = $http.get($rootScope.global_app.uri + '/submission/categories/' + conference_id + '/' + submission_type_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
            }
        }
    ]
);