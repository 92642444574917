'use strict'

angular.module('app').
factory('UtilsService', ['$rootScope', '$http','$log',
        function ($rootScope, $http,$log) {
            return {
                formatErrorMsg: function(msg) {
                    var error_msg = '';

                    if (msg.indexOf('|') !== -1) {
                        error_msg = msg.split('|');

                        var show_error_msg = '';

                        angular.forEach(error_msg, function (value, key) {
                            show_error_msg += value + "<br>";
                        })

                    } else {
                        show_error_msg = msg;
                    }

                    return show_error_msg;
                }
                /*
                ,
                formatPath: function(breadcrumb) {
                    if (breadcrumb) {
                        var bcrumb = breadcrumb.split('|');
                        var display_bcrumb = '';

                        if (bcrumb.length) {
                            angular.forEach(bcrumb, function (value) {
                                display_bcrumb += '<strong>' + value + '</strong> / ';
                            });
                        }

                        return display_bcrumb;
                    }
                },
                formatVenuePath: function(breadcrumb) {

                    if (breadcrumb) {
                        var breadcrumbs = breadcrumb.split('|').map(function (item) {
                            return item.trim();
                        });
                        var venue_path = '';
                        angular.forEach(breadcrumbs, function (b) {
                            venue_path += b + ', ';
                        })

                        // remove trailing ','
                        venue_path = venue_path.substring(0, venue_path.length - 2)

                        return venue_path;
                    }
                }
                */
                ,
                htmlToPlainText: function(text) {
                    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
                }



            };
        }
    ]
);
