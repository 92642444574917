'use strict'

angular.module('app').
    factory('UserService', ['$rootScope', '$http','$log',
        function ($rootScope, $http,$log) {
            return {
                getUser: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/user/show').then(function (response) {

                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getUserProfilePhoto: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/user/profilephoto/get').then(function (response) {

                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                deleteUserProfilePhoto: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/user/profilephoto/delete').then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },

                getCountries: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/user/countries').then(function (response) {

                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getStates: function (country_code){
                    var promise = $http.get($rootScope.global_app.uri + '/user/states/' + country_code).then(function (response) {

                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getProfessions: function (is_clinician){
                    var promise = $http.get($rootScope.global_app.uri + '/user/professions/' + is_clinician).then(function (response) {

                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getSpecialties: function (is_clinician){
                    var promise = $http.get($rootScope.global_app.uri + '/user/specialties/' + is_clinician).then(function (response) {

                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },
                getAreasOfExpertise: function (){
                    var promise = $http.get($rootScope.global_app.uri + '/user/areaofexpertise' ).then(function (response) {

                        return response.data;
                    }, function (error) {
                        //error
                        $log.info('error');
                    })
                    return promise;
                },

                updateUser: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/user/update',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                logUserActivity: function (formData ){
                var promise = $http.post($rootScope.global_app.uri + '/user/log_activity',formData).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    //console.log('error');
                    return false;
                })
                return promise;
                }

        }
        }
    ]);