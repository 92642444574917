'use strict'

angular.module('app').factory('ConferenceService', ['$rootScope', '$http', '$log',
    function ($rootScope, $http, $log) {
        return {
            showConferences: function () {
                var promise = $http.get($rootScope.global_app.uri + '/conferences/showall').then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getConference: function (conference_id) {
                var promise = $http.get($rootScope.global_app.uri + '/conferences/' + conference_id).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getConferenceByKeyword: function (keyword) {
                var promise = $http.get($rootScope.global_app.uri + '/conferences/keyword/' + keyword).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            updateConferenceUserProfile: function (conference_id) {
                var promise = $http.get($rootScope.global_app.uri + '/conferences/update_user_conference_profile/' + conference_id).then(function (response) {
                    return response.data;
                }, function (error) {
                    // error
                    $log.info('error')
                })
                return promise;
            }
        }
    }
]);