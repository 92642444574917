'use strict'

angular.module('app').
factory('ReviewerService', ['$rootScope', '$http','$log',
        function ($rootScope, $http,$log) {
            return {
                updateReviewerProfile: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/reviewer/update',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getReviewAssignments: function (conference_id, reviewer_id){
if (!reviewer_id) {
    var promise = $http.get($rootScope.global_app.uri + '/reviewer/assignments/' + conference_id ).then(function (response) {
        return response.data;
    }, function (error) {
        //error
        //console.log('error');
        return false;
    })
    return promise;
}  else {
    var promise = $http.get($rootScope.global_app.uri + '/reviewer/assignments/' + conference_id + '/' + reviewer_id).then(function (response) {
        return response.data;
    }, function (error) {
        //error
        //console.log('error');
        return false;
    })
    return promise;
}
                },
                exportReviewAssignments: function (conference_id){
                    var promise = $http.get($rootScope.global_app.uri + '/reviewer/export/assignments/' + conference_id ).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getReviewConferences: function (reviewer_id){
                    var promise = $http.get($rootScope.global_app.uri + '/reviewer/conferences/' + reviewer_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getReviewCategories: function (conference_id){
                    var promise = $http.get($rootScope.global_app.uri + '/reviewer/categories/' + conference_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                }
            }
        }
    ]
);