'use strict'

angular.module('app').
    factory('EligibilityService', ['$rootScope', '$http',
        function ($rootScope, $http) {
            return {
                getQuestions: function (conference_id,submission_type_id) {
                    var promise = $http.get($rootScope.global_app.uri + '/eligibility/questions/' + conference_id+'/'+submission_type_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                    })
                    return promise;
                },
                submitEvaluation: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/eligibility/save',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                    })
                    return promise;
                }
            }
        }
    ]);