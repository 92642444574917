'use strict'

angular.module('app').factory('VideoService', ['$rootScope', '$http', '$log',
        function ($rootScope, $http, $log) {
            return {
                deleteVideo: function (formData) {
                    var promise = $http.post($rootScope.global_app.uri + '/video/delete_one', formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                deleteVideos: function (formData){
                    var promise = $http.post($rootScope.global_app.uri + '/video/delete_all',formData).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                },
                getVideos: function (user_submission_id, field_id) {
                    var promise = $http.get($rootScope.global_app.uri + '/video/submission/' + user_submission_id + '/' + field_id).then(function (response) {
                        return response.data;
                    }, function (error) {
                        //error
                        //console.log('error');
                        return false;
                    })
                    return promise;
                }
            }
        }
    ]
);