// lazyload config

var bower_path = "../components/";

angular.module('app')
/**
 * jQuery plugin config use ui-jq directive , config the js and css files that required
 * key: function name of the jQuery plugin
 * value: array of the css js file located
 */
    .constant('JQ_CONFIG', {
        easyPieChart: [
            bower_path+'jquery.easy-pie-chart/dist/jquery.easypiechart.fill.js'
        ],

        sparkline: [
            bower_path+'jquery.sparkline/dist/jquery.sparkline.retina.js'
        ],

        plot: [
            bower_path+'flot/jquery.flot.js',
            bower_path+'flot/jquery.flot.pie.js',
            bower_path+'flot/jquery.flot.resize.js',
            //bower_path+'flot.tooltip/js/jquery.flot.tooltip.js',
            bower_path+'flot.orderbars/js/jquery.flot.orderBars.js',
            bower_path+'flot-spline/js/jquery.flot.spline.js',
            bower_path+'flot/jquery.flot.categories.js'
        ],

        moment: [
            bower_path+'moment/moment.js'
        ],

        screenfull: [
            bower_path+'screenfull/dist/screenfull.min.js'
        ],

        slimScroll: [
            bower_path+'slimscroll/jquery.slimscroll.min.js'
        ],

        sortable: [
            bower_path+'html5sortable/jquery.sortable.js'
        ],

        nestable: [
            bower_path+'nestable/jquery.nestable.js',
            bower_path+'nestable/jquery.nestable.css'
        ],

        filestyle: [
            bower_path+'bootstrap-filestyle/src/bootstrap-filestyle.js'
        ],

        slider: [
            bower_path+'bootstrap-slider/bootstrap-slider.js',
            bower_path+'bootstrap-slider/bootstrap-slider.css'
        ],

        chosen: [
            bower_path+'chosen/chosen.jquery.min.js',
            bower_path+'bootstrap-chosen/bootstrap-chosen.css'
        ],

        TouchSpin: [
            bower_path+'bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.js',
            bower_path+'bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css'
        ],

        wysiwyg: [
            bower_path+'bootstrap-wysiwyg/bootstrap-wysiwyg.js',
            bower_path+'bootstrap-wysiwyg/external/jquery.hotkeys.js'
        ],

        dataTable: [
            bower_path+'datatables/media/js/jquery.dataTables.min.js',
            bower_path+'plugins/integration/bootstrap/3/dataTables.bootstrap.js',
            bower_path+'plugins/integration/bootstrap/3/dataTables.bootstrap.css'
        ],

        vectorMap: [
            bower_path+'bower-jvectormap/jquery-jvectormap-1.2.2.min.js',
            bower_path+'bower-jvectormap/jquery-jvectormap-world-mill-en.js',
            bower_path+'bower-jvectormap/jquery-jvectormap-us-aea-en.js',
            bower_path+'bower-jvectormap/jquery-jvectormap-1.2.2.css'
        ],
        
        footable:       [
            bower_path+'footable/compiled/footable.min.js',
            bower_path+'footable/compiled/footable.bootstrap.min.css'
        ],

        fullcalendar: [
            bower_path+'moment/moment.js',
            bower_path+'fullcalendar/dist/fullcalendar.min.js',
            bower_path+'fullcalendar/dist/fullcalendar.css',
            bower_path+'fullcalendar/dist/fullcalendar.theme.css'
        ],

        daterangepicker: [
            bower_path+'moment/moment.js',
            bower_path+'bootstrap-daterangepicker/daterangepicker.js',
            bower_path+'bootstrap-daterangepicker/daterangepicker-bs3.css'],

        tagsinput: [
            bower_path+'bootstrap-tagsinput/dist/bootstrap-tagsinput.js',
            bower_path+'bootstrap-tagsinput/dist/bootstrap-tagsinput.css'
        ],

        bootstrapSlider: [
            bower_path+'seiyria-bootstrap-slider/dist/bootstrap-slider.min.js',
            bower_path+'seiyria-bootstrap-slider/dist/css/bootstrap-slider.css'
        ]

    }
)
    // oclazyload config
    .config(['$ocLazyLoadProvider', function ($ocLazyLoadProvider) {
        
        $ocLazyLoadProvider.config({
            debug: false,
            events: false,
            modules: [
                {
                    name: 'angular-flot',
                    files: [
                        bower_path+'angular-flot/angular-flot.js'
                    ]
                },
                {
                    name: 'ngGrid',
                    files: [
                        bower_path+'ng-grid/build/ng-grid.min.js',
                        bower_path+'ng-grid/ng-grid.min.css',
                        bower_path+'ng-grid/ng-grid.bootstrap.css'
                    ]
                },
                {
                    name: 'ui.grid',
                    files: [
                        bower_path+'angular-ui-grid/ui-grid.min.js',
                        bower_path+'angular-ui-grid/ui-grid.min.css',
                        bower_path+'angular-ui-grid/ui-grid.bootstrap.css'
                    ]
                },
                {
                    name: 'ui.select',
                    files: [
                        bower_path+'angular-ui-select/dist/select.min.js',
                        bower_path+'angular-ui-select/dist/select.min.css'
                    ]
                },
                {
                    name: 'angularFileUpload',
                    files: [
                        bower_path+'angular-file-upload/angular-file-upload.min.js'
                    ]
                },
                {
                    name: 'ui.calendar',
                    files: [bower_path+'angular-ui-calendar/src/calendar.js']
                },
                {
                    name: 'ngImgCrop',
                    files: [
                        bower_path+'ngImgCrop/compile/minified/ng-img-crop.js',
                        bower_path+'ngImgCrop/compile/minified/ng-img-crop.css'
                    ]
                },
                {
                    name: 'angularBootstrapNavTree',
                    files: [
                        bower_path+'angular-bootstrap-nav-tree/dist/abn_tree_directive.js',
                        bower_path+'angular-bootstrap-nav-tree/dist/abn_tree.css'
                    ]
                },
                {
                    name: 'toaster',
                    files: [
                        bower_path+'angularjs-toaster/toaster.js',
                        bower_path+'angularjs-toaster/toaster.css'
                    ]
                },
                {
                    name: 'textAngular',
                    files: [
                        bower_path+'textAngular/dist/textAngular-sanitize.min.js',
                        bower_path+'textAngular/dist/textAngular.min.js'
                    ]
                },
                {
                    name: 'vr.directives.slider',
                    files: [
                        bower_path+'venturocket-angular-slider/build/angular-slider.min.js',
                        bower_path+'venturocket-angular-slider/build/angular-slider.css'
                    ]
                },
                {
                    name: 'com.2fdevs.videogular',
                    files: [
                        bower_path+'videogular/videogular.min.js'
                    ]
                },
                {
                    name: 'com.2fdevs.videogular.plugins.controls',
                    files: [
                        bower_path+'videogular-controls/controls.min.js'
                    ]
                },
                {
                    name: 'com.2fdevs.videogular.plugins.buffering',
                    files: [
                        bower_path+'videogular-buffering/buffering.min.js'
                    ]
                },
                {
                    name: 'com.2fdevs.videogular.plugins.overlayplay',
                    files: [
                        bower_path+'videogular-overlay-play/overlay-play.min.js'
                    ]
                },
                {
                    name: 'com.2fdevs.videogular.plugins.poster',
                    files: [
                        bower_path+'videogular-poster/poster.min.js'
                    ]
                },
                {
                    name: 'com.2fdevs.videogular.plugins.imaads',
                    files: [
                        bower_path+'videogular-ima-ads/ima-ads.min.js'
                    ]
                },
                {
                    name: 'xeditable',
                    files: [
                        bower_path+'angular-xeditable/dist/js/xeditable.min.js',
                        bower_path+'angular-xeditable/dist/css/xeditable.css'
                    ]
                },
                {
                    name: 'smart-table',
                    files: [
                        bower_path+'angular-smart-table/dist/smart-table.min.js'
                    ]
                },
                {
                    name: 'angularSpinner',
                    files: [
                        bower_path+'angular-spinner/angular-spinner.min.js'
                    ]
                },                
                {
                    name: 'ngCkeditor',
                    files: [
                        bower_path+'ng-ckeditor/ng-ckeditor.css',
                        bower_path+'ng-ckeditor/ng-ckeditor.min.js'
                    ]
                },
                {
                    name: 'ui.tinymce',
                    files: [
                        bower_path+'angular-ui-tinymce/src/tinymce.js'
                    ]
                },
                {
                    name: 'ngTagsInput',
                    files: [
                        bower_path+'ng-tags-input/ng-tags-input.js'
                    ]
                },
                {
                    name: 'jsTag',
                    files: [
                        bower_path+'jsTag/jsTag/compiled/jsTag.css',
                        bower_path+'jsTag/jsTag/compiled/jsTag.min.js'
                    ]
                },
                {
                    name: 'siyfion.sfTypeahead',
                    files: [
                        bower_path+'typeahead.js/dist/typeahead.bundle.js',
                        bower_path+'angular-typeahead/angular-typeahead.min.js'
                    ]
                },
                {
                    name: 'ngFileUpload',
                    files: [
                        bower_path+'ng-file-upload/ng-file-upload-all.js'
                    ]
                },
                {
                    name: 'bootstrapLightbox',
                    files: [
                        bower_path+'angular-bootstrap-lightbox/dist/angular-bootstrap-lightbox.min.js',
                        bower_path+'angular-bootstrap-lightbox/dist/angular-bootstrap-lightbox.min.css'
                    ]
                }
 /*               ,
                {
                    name: 'angularMoment',
                    files: [
                       bower_path+'angular-moment/angular-moment.min.js'
                    ]
                }
*/
            ]
        });
    }]);

